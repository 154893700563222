<template>
  <DashboardSkeleton>
    <template v-slot:left-drawer>
      <TheAdvertiserLeftDrawer />
    </template>
    <template v-slot:above-view>
      <AdvertiserAccountSelectionBreadcrumb
        v-if="
          $route.params.advertiserAccountId &&
            $route.path.includes('/advertiser/accounts/') &&
            $route.path.includes('/import-history')
        "
        :disable="
          $route.params.inventorySourceId &&
            $route.path.includes('/inventory-sources/')
        "
      />
      <!--<ProductTypeSelectionBreadcrumb
        v-if="$route.path.includes('/products/')"
      />-->
    </template>
  </DashboardSkeleton>
</template>

<script>
import TheAdvertiserLeftDrawer from "@/components/TheAdvertiserLeftDrawer";
import DashboardSkeleton from "@/components/Skeletons/DashboardSkeleton";
// import { Dialog } from "quasar";
// import AdvertiserWelcomeModal from "@/components/Advertiser/AdvertiserWelcomeModal";
import AdvertiserAccountSelectionBreadcrumb from "@/components/Advertiser/AdvertiserAccountSelectionBreadcrumb";
// import ProductTypeSelectionBreadcrumb from "@/components/Advertiser/ProductTypeSelectionBreadcrumb";

export default {
  name: "AdvertiserDashboard",
  components: {
    // ProductTypeSelectionBreadcrumb,
    AdvertiserAccountSelectionBreadcrumb,
    DashboardSkeleton,
    TheAdvertiserLeftDrawer
  },
  data() {
    return {
      showWelcomeModal: false,
      welcomeModalSlide: 1
    };
  },
  watch: {},
  mounted() {
    /*if (this.$store.getters["business/advertiserInitialFlowRestricted"]) {
      Dialog.create({
        component: AdvertiserWelcomeModal
      });
    }*/
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
